const $ = require('jquery')

const $siteMenu = $('.js-site-nav')
const $menus = $siteMenu.find('.menu-wrapper')
const subMenuLinks = $siteMenu.find('li.has-children')
const subMenus = $('.js-site-nav > nav > .menu-wrapper').find('.menu-wrapper')

function toggleNav(){
  var navIsVisible = ( !$('body').hasClass('nav--is-active') ) ? true : false
  console.log(navIsVisible)
  $('body').toggleClass('nav--is-active', navIsVisible)
  $siteMenu.toggleClass('nav--is-active', navIsVisible)
  if( !navIsVisible ) {
    $siteMenu.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
      subMenuLinks.removeClass('active')
    })
  }
}

//open/close mega-navigation
$('.js-site-nav-trigger').on('click', function(e){
  e.preventDefault()
  toggleNav()
})

// $(document).on('click', function (e) {
//   if (!$(e.target).closest('.site-nav, .js-site-nav-trigger').length) {
//     $('body').removeClass('nav--is-active')
//     $siteMenu.removeClass('nav--is-active')
//   }
// })
// $(window).on('scroll', function (e) {
//   if (!$(e.target).closest('.site-nav, .menu-wrapper, .js-site-nav-trigger').length) {
//     // toggleNav()
//     $('body').removeClass('nav--is-active')
//     $siteMenu.removeClass('nav--is-active')
//   }
// })



// Select nav items that have submenus
var hasSubmenu = document.querySelectorAll('[data-id]');
var active = 'active';
var i = 0;

// Show the submenu by toggling the relevant class names
function showSubmenu (event) {
  // We lose reference of this when filtering the nav items
  var self = this;

  // Select the relevant submenu, by the data-id attribute
  var submenu = document.getElementById(self.dataset.id);

  // Probably best to prevent clicks through
  event.preventDefault();

  // Referring to the submenu parentNode
  // find all elements that aren't the submenu and remove active class
  var otherSubmenu = Array.prototype.filter.call(
    submenu.parentNode.children,
    function(child) {
      if ( child !== submenu ) {
        removeChildClass(child);
      }
    });

  // Referring to the the nav item parentNode
  // find all elements that aren't the submenu and remove active class
  var otherItem = Array.prototype.filter.call(
    self.parentNode.children,
    function(child) {
      if ( child !== self ) {
        removeChildClass(child);
      }
    });

  self.classList.toggle(active);
  submenu.classList.toggle(active);
}

// Remove the active class
function removeChildClass(el) {
  // Check if it exists, then remove
  if ( el.classList.contains(active) ) {
    el.classList.remove(active);
  }
}

// On clicks show submenus
for ( i = 0; i < hasSubmenu.length; i++ ) {
  hasSubmenu[i].addEventListener('click', showSubmenu);
}
