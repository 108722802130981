require('browsernizr/lib/html5shiv')
require('browsernizr/lib/domPrefixes')
require('browsernizr/lib/mq')
require('browsernizr/lib/testPropsAll')
require('browsernizr/test/css/flexbox')
require('browsernizr/test/css/rgba')
require('browsernizr/test/css/transitions')
require('browsernizr/test/css/animations')
require('browsernizr/test/css/gradients')
require('browsernizr/test/forms/placeholder')
require('browsernizr/test/svg')

// make sure to do this _after_ importing the tests
require('browsernizr')
global.Modernizr = require('browsernizr')

// if (Modernizr.backgroundblendmode) {
   // $('.post__image img').css('opacity', '0')
   // $('.bg-effect').css('display', 'block')
 // } else {
   // $('.post__image img').css('opacity', '1')
   // $('.bg-effect').css('display', 'none')
 // }
// if (typeof Modernizr == 'object') {
//   if (!Modernizr.touch) {
//     console.log('Touch device')
//   }
// }
