// JS Goes here - ES6 supported
const $ = require('jquery')
global.jQuery = global.$ = $

require('./component/modernizr-init')
global.fancybox = require('@fancyapps/fancybox')
require('jquery.scrollto')
require('jquery.localscroll')
global.matchHeight = require('jquery-match-height')

$.extend($.scrollTo.defaults, {
  axis: 'y',
  duration: 800,
  offset:0,
});

require('./component/navigation')

$('.back-to-top a').on('click', function (e) {
  e.preventDefault()
  $.scrollTo('#start')
})

$('.small-box-listing .box__content').matchHeight({
  byRow: true,
  property: 'height',
  target: null,
  remove: false
});

$('.small-box-listing .box__image').matchHeight({
  byRow: true,
  property: 'height',
  target: null,
  remove: false
});

